import React from "react";
import { useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { useI18next } from "gatsby-plugin-react-i18next";

/*
Shows a drop down list for changing language locales
returns null when no languages provided
*/
const LanguageSelector = ({
  className = "header-dropdown-link",
  showIcon = true,
}) => {
  // All region languages display when DEVELOPMENT env
  const { languages: languagesByRegion } = useSelector(
    (state) => !state.region_loading && state.region_data
  );
  const { changeLanguage, language } = useI18next();

  const titleElement = (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      {showIcon && <FontAwesomeIcon icon={faGlobe} className="mr-1" />}
      {language}
      <FontAwesomeIcon icon={faAngleDown} />
    </span>
  );

  return languagesByRegion && languagesByRegion.length > 1 ? (
    <NavDropdown title={titleElement} className={className}>
      {languagesByRegion.map((lng) => (
        <NavDropdown.Item
          key={lng}
          onClick={(e) => {
            e.preventDefault();
            changeLanguage(lng);
          }}
        >
          {lng}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  ) : (
    "EN"
  );
};

export default LanguageSelector;
